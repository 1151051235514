import { useTranslation } from "react-i18next";

const StarRating = ({ rating, starDimension, size = "lg" }) => {
  const {
    i18n: { language: locale },
  } = useTranslation();

  const starCount = 5;
  const starSize = starDimension ? starDimension : size === "lg" ? 15 : 11;
  const starColor = "#FED000";
  const emptyStarColor = "#e4e5e9";
  const isRTL = locale === "ar";

  const getStarStyles = (index) => {
    const ratingDecimal = parseFloat(rating);
    const filledStars = Math.floor(ratingDecimal);
    const ratingRemainder = ratingDecimal % 1;
    const fillPercentage = ratingRemainder * 100;
    const emptyPercentage = 100 - fillPercentage;
    const starSizePx = `${starSize}px`;

    let starStyle = {
      fontSize: starSizePx,
      position: "relative",
      display: "inline-block",
    };

    if (isRTL) {
      starStyle = {
        ...starStyle,
        transform: "scaleX(-1)",
      };

      if (index < filledStars) {
        starStyle = {
          ...starStyle,
          color: starColor,
        };
      } else if (
        index === filledStars &&
        fillPercentage >= 25 &&
        fillPercentage <= 75
      ) {
        starStyle = {
          ...starStyle,
          backgroundImage: `linear-gradient(to left, ${emptyStarColor} ${emptyPercentage}%, ${starColor} ${fillPercentage}%)`,
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        };
      } else {
        starStyle = {
          ...starStyle,
          color: emptyStarColor,
        };
      }
    } else {
      if (index < filledStars) {
        starStyle = {
          ...starStyle,
          color: starColor,
        };
      } else if (
        index === filledStars &&
        fillPercentage >= 25 &&
        fillPercentage <= 75
      ) {
        starStyle = {
          ...starStyle,
          backgroundImage: `linear-gradient(to right, ${starColor} ${fillPercentage}%, ${emptyStarColor} ${emptyPercentage}%)`,
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        };
      } else {
        starStyle = {
          ...starStyle,
          color: emptyStarColor,
        };
      }
    }

    return starStyle;
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < starCount; i++) {
      stars.push(
        <span key={i} style={getStarStyles(i)}>
          {i === Math.floor(rating) && rating % 1 !== 0 ? (
            <i className="fas fa-star-half-alt"></i>
          ) : (
            <i className="fas fa-star"></i>
          )}
        </span>
      );
    }
    return stars;
  };

  return <div className="flex items-center">{renderStars()}</div>;
};

export default StarRating;
